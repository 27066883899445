body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0;
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  }
  html {
    height: -webkit-fill-available;
  }
  